// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

$ = require('jquery');
window.$ = window.jQuery = $;
require('slick-carousel');
require('readmore-js');
require('bootstrap');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

require('../../assets/stylesheets/stylesheets.js');

const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)

let Utility = {
    paragraph: function() {
        $('div > p').each(function() {
            $(this).attr("x-ms-format-detection", "none");
        });
    },
    menu: function() {
        let $hamburger = $('.hamburger-box');
        let $nav = $('nav.menu');
        $hamburger.on('click touch', function() {
            $hamburger.toggleClass('expanded');
            $nav.toggleClass('expanded');
        });
    },
    // ToDo: Probably not needed
    anchor: function() {
        let $root = $('html, body');
        $('a[href^="#"].anchor').click(function(e) {
            e.preventDefault();
            let href = $.attr(this, 'href');
            if (href !== '#') {
                let $target = $(href);
                if ($target.length) {
                    alert('ha');
                    $root.animate({
                        scrollTop: $target.offset().top
                    }, 500, function() {
                        window.location.hash = href;
                    });
                }
            }
            return false;
        });
    },

    readmore: function() {
        let less = '<a href="#">méně</a>';
        let more = '<a href="#">více</a>';
        let speed = 75;
        let screenXsMax = 768;

        function readmore() {
            let windowWidth = $(window).width();
            let faqHeight = 58;

            if (windowWidth <= screenXsMax) {
                faqHeight = 50;
            }

            $('#faqs article .content').readmore({
                collapsedHeight: faqHeight,
                speed: speed,
                lessLink: less,
                moreLink: more
            });
        }

        readmore();
        $(window).on('resize', function() {
            readmore();
        });
    },
    getHash: function() {
        return window.location.hash;
    },
    carousel: function() {
        $('.slider').slick({
            arrows: true,
            dots: true
        });
    },
    contact: function () {
        let $contactInfo = $('#contact-info');
        let $button = $contactInfo.find('a.button');
        let $contactForm = $('#contact');

        function showContactForm() {
            $contactInfo.hide();
            $contactForm.show();
            setTimeout(function() {
                $('html, body').animate({
                    scrollTop:  $contactForm.offset().top
                });
            }, 0);
        }

        if (this.getHash() === '#contact') {
            showContactForm();
        }

        $button.on('click touch', function(e) {
            e.preventDefault();
            window.location.hash = 'contact';
            showContactForm();
        })
    },
    init: function() {
        this.menu();
        this.paragraph();
        this.anchor();
        this.readmore();
        this.carousel();
        this.contact();
        this.analytics();
    },
    analytics: function() {
        const form = document.getElementById('contact-form');
        form.addEventListener('submit', function (event) {
            const campaign = event.target.dataset.campaign;
            event.preventDefault();
            // Creates a timeout to call submitForm after one second.
            setTimeout(submitForm, 1000);
            let formSubmitted = false;

            function submitForm() {
                if (!formSubmitted) {
                    formSubmitted = true;
                    form.submit();
                }
            }

            gtag('event', 'contact_form_complete', {
                'campaign': campaign,
                'event_label': campaign || 'no campaign',
                'event_callback': submitForm });
        });
    }
}

$(document).ready(function(e) {
    Utility.init();
})
